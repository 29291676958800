/* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import Logo from '../../assets/go.png';
import { itemsAdmin } from './Admin';
import { itemsUser } from './User';
import { itemsCorretora } from './Corretora';
import { itemsSeguradora } from './Seguradora';
import { getCookieSessionData, logout } from '../../services/cookieService';
import { isAllowedByRole } from '../../services/cookieService';
import { permissionamento } from '../../services/permissionamento';
import './styles.css';
import { BsPlusLg } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';
import { menuContext } from '../../context/MenuContext';

export const handleLogout = () => {
  logout();
};

function MenuLateral() {
  const { usuarioId } = getCookieSessionData().usuarioVO;
  const { show, setShow } = useContext(menuContext);

  const savePermissoes = async () => {
    await permissionamento(usuarioId);
  };

  useEffect(() => {
    savePermissoes();
  }, [usuarioId]);

  const showMenu = (e: any) => {
    e.preventDefault();
    setShow(!show);
  };

  function handleResize() {
    if (window.innerWidth < 700) {
      console.log(window.innerWidth);
      setShow(false);
    }
  }

  window.addEventListener('resize', handleResize);

  return (
    <>
      <div
        className="menu-lateral"
        style={{
          display: show ? 'block' : 'none',
          height: '100%',
          // background: 'red',
          width: '30vh',
          // maxWidth: '15%',
          justifyContent: 'center',
          backgroundColor: '#f8f9fa',
          borderInlineEndStyle: 'solid',
          borderInlineEndWidth: '1px',
          borderInlineEndColor: '#dee2e6',
        }}
      >
        <img
          src={Logo}
          style={{
            margin: '2vh',
            width: '85%',
            background: '#f8f9fa',
          }}
        />

        <div
          style={{
            paddingLeft: '7.5%',
            paddingRight: '7.5%',
          }}
        >
          <p
            style={{
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: `bold`,
            }}
          >
            {`Seja bem vindo(a), `}
          </p>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {isAllowedByRole(['user']) && `${getCookieSessionData().usuarioVO.clienteRazaoSocial}.`}
            {isAllowedByRole(['admin']) && `${getCookieSessionData().usuarioVO.nome}.`}
            {isAllowedByRole(['corretora']) && `${getCookieSessionData().usuarioVO.corretoraNome}.`}
            {isAllowedByRole(['seguradora']) &&
              `${getCookieSessionData().usuarioVO.seguradoraNome}.`}
          </p>
        </div>

        {isAllowedByRole(['user']) && (
          <PanelMenu
            model={itemsUser()}
            style={{
              height: '100%',
              width: '100%',
              fontSize: '12px',
              labelSize: '12px',
              titleSize: '12px',
            }}
          ></PanelMenu>
        )}

        {isAllowedByRole(['admin']) && (
          <PanelMenu
            model={itemsAdmin}
            style={{
              height: '100%',
              width: '100%',
              fontSize: '12px',
              labelSize: '12px',
              titleSize: '12px',
            }}
          ></PanelMenu>
        )}

        {isAllowedByRole(['corretora']) && (
          <PanelMenu
            model={itemsCorretora}
            style={{
              height: '100%',
              width: '100%',
              fontSize: '12px',
              labelSize: '12px',
              titleSize: '12px',
            }}
          ></PanelMenu>
        )}

        {isAllowedByRole(['seguradora']) && (
          <PanelMenu
            model={itemsSeguradora}
            style={{
              height: '100%',
              width: '100%',
              fontSize: '12px',
              labelSize: '12px',
              titleSize: '12px',
            }}
          ></PanelMenu>
        )}
        <div style={{ paddingTop: '8px' }}>
          <p
            style={{
              fontSize: '10px',
              marginBottom: '0%',
              marginLeft: '8%',
              color: '#acacac',
            }}
          >
            {`Versão 0.3.4.0`}
          </p>
        </div>
      </div>
      <div>
        <button className="openMenu" onClick={showMenu}>
          {show ? <MdClose size={22} /> : <IoIosArrowForward size={22} />}
        </button>
      </div>
    </>
  );
}

export default MenuLateral;
