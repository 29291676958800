import { getCookieSessionData } from '../../services/cookieService';

export const defaultRotaPonto = {
  onlyDataPonto: '',
  onlyTimePonto: '',
  dataPonto: '',
  idPonto: 0,
  idSm: 0,
  idSmPv: 0,
  idUsuario: 0,
  remessa: '',
  sequencia: 0,
  tipoPonto: '',
};

export const defaultModel = {
  agente2Escolta: '',
  agenteEscolta: '',
  cancelamento: false,
  dataCadastro: '',
  dataFim: '',
  dataFimReal: '',
  dataHoraCancelamento: '',
  dataInicio: '',
  dataInicioReal: '',
  descricaoCarga: '',
  dtSolicitacaoCancelamentoCli: '',
  empresaEscolta: '',
  flagEscolta: false,
  flagIsca: false,
  flagMonitoramentoEscoltaGs: false,
  horaFim: '',
  horaFimReal: '',
  horaInicio: '',
  horaInicioReal: '',
  idAjudante: 0,
  idAjudante2: 0,
  idBau: 0,
  idBauCheck: 0,
  idBauSat: 0,
  idCar2Check: 0,
  idCar2Sat: 0,
  idCarCheck: 0,
  idCarSat: 0,
  idCarreta: 0,
  idCarreta2: 0,
  idCheck: 0,
  idCliCancelamento: 0,
  idCliente: 0,
  idCondutor: 0,
  idCondutor2: 0,
  idEmbarcador: 0,
  idParada: 0,
  idPreSM: 0,
  idRota: 0,
  idSat: 0,
  idTecnologiaEscolta: 0,
  idTecnologiaIsca: 0,
  idTipoComunicacaoEscolta: 0,
  idTipoComunicacaoIsca: 0,
  idTipoOperacaoCliente: 0,
  idUsuarioCadastro: 0,
  idUsuarioCancelamento: 0,
  idUsuarioFim: 0,
  idUsuarioInicio: 0,
  idVeiculo: 0,
  iscaNumEquipamento: '',
  iscaSite: '',
  iscaSiteLogin: '',
  iscaSiteSenha: '',
  motivoCancelamento: 0,
  motivoFinalizacao: '',
  numeroEquipamentoTecnologiaEscolta: '',
  obs: '',
  obsPesquisa: '',
  paradaVO: [
    {
      ativo: false,
      bairro: '',
      cep: '',
      cidade: '',
      complemento: '',
      id: 0,
      idCodigoIbge: 0,
      idSm: 0,
      idUf: 0,
      local: '',
      logradouro: '',
      motivo: '',
      numero: 0,
      rotaId: 0,
      sequencia: 0,
      statusAtivo: false,
    },
  ],
  romaneio: '',
  solicitacaoMonitoramentoPlanoViagemVO: [defaultRotaPonto],
  solicitarFrotaCliente: '',
  solicitarIdentificadorCliente: '',
  somCdId: 0,
  statusAtivo: false,
  statusFinalizacao: false,
  statusInicializacao: false,
  statusVisualizacao: false,
  telAgente2Escolta: '',
  telAgenteEscolta: '',
  telEscolta: '',
  temperatura: 0,
  temperaturaMaxima: 0,
  temperaturaMinima: 0,
  tipoOperacao: 0,
  usuario: '',
  valorCarga: 0,
  veiculoEscolta: '',
  velocidadeMaxima: 0,
};

export const defaultVeiculo = {
  bloqueado: false,
  checklist: '',
  dtValidadePesquisa: '',
  id: 0,
  modelo: '',
  pesquisa: '',
  placa: '',
  quantidadeViagem: 0,
  idSat: 0,
  idCheck: 0,
  sat: false,
  statusCritica: '',
  statusValidadeChecklist: '',
  statusValidadePesquisa: '',
  tecnologia: '',
  tipoFrota: '',
  tipoCarreta: '',
  valorMedioTransportado: 0,
  nomeTipoFrota: '',
  meioComunicacao: '',
  numeroEquipamento: '',
  tipoVeiculo: '',
};

export const defaultCarreta = {
  id: 0,
  placa: '',
  modelo: '',
  tipoFrota: '',
  checklist: '',
  sat: 0,
  pesquisa: '',
  quantidadeViagem: 0,
  valorMedioTransportado: 0,
  nomeTipoFrota: '',
  tipoCarreta: '',
  tipoGeralCarreta: '',
};

export const defaultAjudante = {
  bloqueado: false,
  cpf: '',
  dtValidadePesquisa: '',
  id: 0,
  nome: '',
  pesquisa: '',
  qtdeViagens: 0,
  statusCritica: '',
  statusValidadePesquisa: '',
  tipo: '',
  valorMedioTransportado: 0,
};

export const defaultCondutor = {
  bloqueado: false,
  cpf: '',
  dtValidadePesquisa: '',
  id: 0,
  nome: '',
  pesquisa: '',
  quantidadeViagem: 0,
  statusCritica: '',
  statusValidadePesquisa: '',
  tipo: '',
  valorMedioTransportado: 0,
  vigenciaCondutor: false,
};

export const defaultBau = {
  checklist: '',
  dataInclusaoSat: '',
  id: 0,
  idChecklist: 0,
  idCliente: 0,
  idSat: 0,
  modelo: '',
  nomeResponsavel: '',
  numeroBau: '',
};

export const defaultBloqueiosPorPontos = {
  condutor1: false,
  condutor2: false,
  veiculo: false,
  carreta1: false,
  carreta2: false,
};

export const defaultServicosContratados = {
  cliCdId: 0,
  cscCdId: 0,
  cscNuMonitoramentoVeiculos: 0,
  cscNuCadastroConsulta: 0,
  cscNuMapaRastreamento: 0,
  cscNuWsposicoes: 0,
  cscNuSmsviagem: 0,
  cscNuCronogramaViagens: 0,
  cscNuRotaPorPontos: 0,
  razaoSocialCliente: '',
  controleTemperatura: 0,
  controleVelocidade: 0,
  painelLsc: 0,
};

export const defaultParticularidades = {
  flagBloqueioSATNaSM: 0,
  flagCritiarAnaliseFrotaPropriaNaSM: false,
  flagCriticarAnaliseAgregadoNaSM: false,
  flagCriticarAnaliseCondutorAgregadoNaSM: false,
  flagCriticarAnaliseCondutorAutonomoNaSM: false,
  flagCriticarAnaliseCondutorFuncionarioNaSM: false,
  flagCriticarAnaliseNaoMotoristaAgregadoNaSM: false,
  flagCriticarAnaliseNaoMotoristaAutonomoNaSM: false,
  flagCriticarAnaliseNaoMotoristaNaSM: false,
  flagCriticarAnaliseTerceiroNaSM: false,
  flagDadosEnderecoObrigatorio: false,
  flagExecutarRotaInternacional: false,
  flagHabilitaPesquisasConsultasVigentes: false,
  flagHabilitarPesquisaAnttRntrc: false,
  flagIncluirCdCadPonto: false,
  flagIncluirRegionalCadPonto: false,
  flagPermitirEdicao: false,
  flagPermitirPesquisaOutraEmpresa: false,
  flagSolicitarFrotaNaSM: false,
  flagSolicitarNumeroIdentificadorNaSM: false,
  flagSolicitarNumeroRemessaNaSM: false,
  flagSolicitarOperacaoNaSM: false,
  flagUtilizaApolicePropria: false,
  flagUtilizaRankingAjudanteNaSM: false,
  flagUtilizaRankingBauNaSM: false,
  flagUtilizaRankingCarretaNaSM: false,
  flagUtilizaRankingCondutorNaSM: false,
  flagUtilizaRankingVeiculoNaSM: false,
  flagValidarChecklistPendente: 0,
  id: 0,
  idCliente: 0,
  isEmbarcador: false,
  numeroDiasDeVeiculoAgregado: 0,
  numeroDiasDeVeiculoFrotaPropria: 0,
  numeroDiasVeiculoFrotaEspecial: 0,
  numeroDiasVeiculoTerceiro: 0,
  validadeAnaliseCondutorAgregado: 0,
  validadeAnaliseCondutorAutonomo: 0,
  validadeAnaliseCondutorFuncionario: 0,
  validadeAnaliseNaoMotoristaAgregado: 0,
  validadeAnaliseNaoMotoristaAutonomo: 0,
  validadeAnaliseNaoMotoristaFuncionario: 0,
  validadeAnaliseVeiculoAgregado: 0,
  validadeAnaliseVeiculoFrotaPropria: 0,
  validadeAnaliseVeiculoTerceiro: 0,
};

export const defaultCriticas = {
  condutor1: {
    pesquisa: '',
  },
  condutor2: {
    pesquisa: '',
  },
  ajudante1: {
    pesquisa: '',
  },
  ajudante2: {
    pesquisa: '',
  },
  veiculo: {
    pesquisa: '',
  },
  carreta1: {
    pesquisa: '',
  },
  carreta2: {
    pesquisa: '',
  },
};

// const { clienteId } = getCookieSessionData().usuarioVO;

export const defaultLogin = {
  usuario: '',
  senha: '',
  isLogado: false,
};
